<!--耐力指标设置  -->
<template>
  <div>
    <div class="child-top">
      <div class="searchArea input-from">
        <el-input
          class="input-with-select"
          clearable
          placeholder="请输入指标分类名称"
          style="width:200px;"
          suffix-icon="iconfont iconsousuo"
          v-model="form.keyword"
        ></el-input>
        <el-button class="searchBtn" @click="getData" round>搜索</el-button>
      </div>
      <div class="operation-button">
        <el-button @click="addDataLog" class="new_btn" round
        :disabled="$store.getters.permissionsStr('新增') "
        >
          <span>新增</span>
        </el-button>
        <el-button class="del_btn"  round @click="someDels()"
        :disabled="$store.getters.permissionsStr('删除') || !sels.length"
        >
          <span>删除</span>
        </el-button>
      </div>
    </div>
    <div style="background: #fff;">
      <el-table
         :empty-text="form.tableList.length ? '' : '暂无数据'"
        v-loading="tableLoading"
        @selection-change="selsChange"
        class="content-test-table"
        :data="!$store.getters.permissionsStr('列表') ? form.tableList : []"
        style="width: 100%"
      >
        <el-table-column
          :selectable="checkboxSelect"
          show-overflow-tooltip
          type="selection"
          width="45"
        ></el-table-column>
        <el-table-column label="序号" type="index" width="60">
          <template slot-scope="scope">{{ (form.page - 1) * form.total + scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="指标分类名称" prop="name" show-overflow-tooltip ></el-table-column>
        <el-table-column label="所含指标数"  prop="norm_quantity" show-overflow-tooltip></el-table-column>
        <el-table-column label="顺序" prop="order_no"  show-overflow-tooltip></el-table-column>
        <el-table-column label="备注"  prop="demo" show-overflow-tooltip></el-table-column>
        <el-table-column label="状态" prop="status" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
              v-show="scope.row.uuid"
              active-color="#0055E9"
              :active-value="1"
              :inactive-value="0"
              :width="35"
              @change="ChangeState(scope.row)"
              inactive-color="#ccc"
              v-model="scope.row.status"
               :disabled="$store.getters.permissionsStr('启/停用')"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              :disabled="$store.getters.permissionsStr('编辑') || scope.row.edit_flag!==1"
              :class="scope.row.edit_flag==1? 'blueColor right20' : 'disColor right20'"
              @click="postEdit(scope.row)"
              size="medium"
              type="text"
               v-show="scope.row.uuid"
               
            >编辑</el-button>
            <el-button
              v-if="scope.row.edit_flag == 1"
              :disabled="$store.getters.permissionsStr('选择指标')"
              :class="scope.row.edit_flag==1? 'blueColors right20' : 'disColor right20'"
              size="medium"
              type="text"
              @click="getIndicator(scope.row)"
              v-show="scope.row.uuid"
            >选择指标</el-button>
            <el-button
              v-else
              class="yellowColor right20"
              size="medium"
              type="text"
              @click="getIndicator(scope.row, true)"
              v-show="scope.row.uuid"
            >查看指标</el-button>
            <el-button
              :disabled="$store.getters.permissionsStr('删除') || scope.row.edit_flag!==1"
              :class="scope.row.edit_flag==1? 'redColor right20' : 'disColor right20'"
              @click="Delete(scope.row)"
              size="medium"
              type="text"
              v-show="scope.row.uuid"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="form.page"
        :page-size="form.page_siaze"
        :total="form.total"
        @current-change="handleCurrentChange"
        class="table-pagination"
        layout="total, jumper,  ->, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- 新增编辑弹框 -->
    <div>
      <el-dialog
        @close="clear"
        :close-on-click-modal="false"
        :visible.sync="centerDialogVisible"
        :title="titleMap[dialogStatus]"
        center
        style="text-align:center;"
        width="50%"
      >
        <div style="margin-bottom:40px;">
          <el-form
            :rules="rules"
            :model="addform"
            ref="addform"
            :label-position="'left'"
            label-width="82px"
          >
            <div style="display: flex;">
              <el-form-item label="指标分类名称" prop="cn_name" style="flex: 1;">
                <el-input
                  auto-complete="off"
                  v-model="addform.cn_name"
                  placeholder="请输入指标分类名称"
                  style="width:80%"
                  max="40"
                ></el-input>
              </el-form-item>
              <el-form-item label="顺序" prop="order_no" style="flex: 1;">
                <el-input
                  maxlength="10"
                  auto-complete="off"
                  v-model="addform.order_no"
                  placeholder="请输入顺序 "
                  style="width:80%"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item label="分类属性" prop="edit_flag" required>
              <el-radio v-model="addform.radio" :label="1">可编辑</el-radio>
              <el-radio v-model="addform.radio" :label="0">只读</el-radio>
            </el-form-item>
            <el-form-item label="备注" prop="demo">
              <el-input :rows="4" v-model="addform.demo" style="width:96%" type="textarea"></el-input>
            </el-form-item>
          </el-form>
        </div>

        <div class="form-bottom-button">
          <el-button @click="submitForm" type="primary" v-no-more-click class="save_btn" round>确 定</el-button>
          <el-button @click="centerDialogVisible = false" class="cancel_btn" round>取 消</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 选择指标弹窗 -->
    <treeTansfer></treeTansfer>

    <el-dialog
          @close="lookCancel"
          :close-on-click-modal="false"
          :visible.sync="lookDialogVisible"
          :title="lookTitle"
          center
          style="text-align:center;"
          width="30%"
        >
      <ul class="look_item_content" style="margin-bottom:40px;">
        <li v-for="item in lookZbList" :key="item.uuid">{{item.cn_name}}</li> 
      </ul>

      <div class="form-bottom-button">
        <el-button @click="lookCancel" class="cancel_btn" round>关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableLoading: true, //loading
      titleMap: {
        createde: "新增指标分类",
        Preview: "编辑指标分类"
      },
      // 选择指标
      zbList: [],
      lookTitle: '',
      lookZbList: [],
      lookDialogVisible: false,
      //新增和编辑弹框标题
      dialogStatus: "",
      //新增数据
      addform: {
        cn_name: "", //名称
        demo: "", //备注
        order_no: "", //顺序
        uuid: "",
        radio: 1
      },
      centerDialogVisible: false, //新增编辑关闭弹框
      dialog_type: false,
      defaultProps: {
        children: "norm",
        label: "name"
      },
      treeData: [],
      setUuid: "",
      //列表数据
      form: {
        tableList: [{uuid:0}],
        page: 1, //当前页
        page_size: 10,
        keyword: "", //搜索框
        total: 0,
        numType: "6"
      },
      selsuuid: [], //选中uuid
      sels: [], // 列表选中列
      rules: {
        cn_name: [
          { required: true, message: "请输入指标单位名称", trigger: "blur" },
          {
            max: 40,
            message: "最大长度40位",
            trigger: "blur"
          }
        ],
        demo: [
          {
            max: 80,
            message: "最大长度80位",
            trigger: "blur"
          }
        ],
        order_no: [
          // {
          //   max: 10,
          //   message: "最大长度10位",
          //   trigger: "blur"
          // },
          {
            validator: (rule, value, callback) => {
              if (value) {
                if (!/^\+?[0-9][0-9]*$/g.test(value)) {
                  callback(new Error("顺序只能输入整数数字"));
                } else {
                }
              }
            },
            trigger: "blur"
          }
        ]
      }
    };
  },

  computed: {},

  created() {},
  mounted() {
    this.getData();
    this.getIndicators();
  },
  watch: {
    dialog_type(newVal) {
      if (!newVal) {
        this.zbList.map(item => {
          item.isCheck = false;
          item.isShow = true;
        });
      }
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData();
    },
    selsChange(sels) {
      this.sels = sels;
    },
    //清空弹窗数据
    clear() {
      this.$refs["addform"].resetFields();
      //清除提示信息
      this.$refs["addform"].clearValidate();
      this.addform.cn_name = ""; //名称
      this.addform.demo = ""; //备注
      this.addform.order_no = ""; //顺序
      this.addform.radio = 1;
    },
    //获取列表数据
    getData() {
      let params = {
        type: this.form.numType,
        page: this.form.page,
        page_size: this.form.page_size,
        search_word: this.form.keyword
      };
      let url = "/p/cate/catelist";
      this.$axios.post(url, this.$qs(params)).then(res => {
        if (res.data.code == 0) {
          this.form.tableList = res.data.data.rows;
          this.form.total = res.data.data.total;
          this.tableLoading = false;
        }
      });
    },
    //新增数据
    addDataLog() {
      this.centerDialogVisible = true;
      this.dialogStatus = "createde";
    },
    //禁用selection
    checkboxSelect(row, rowIndex) {
      if (row.edit_flag == 1) {
        return true; // 不禁用
      } else {
        return false; // 不禁用
      }
    },
    //编辑数据
    postEdit(data) {
      this.dialogStatus = "Preview";
      this.addform.uuid = data.uuid;
      this.addform.cn_name = data.name;
      this.addform.demo = data.demo;
      this.addform.order_no = data.order_no;
      this.addform.radio = data.edit_flag;
      this.centerDialogVisible = true;
    },
    //新增保存
    submitForm(data) {
      if (this.dialogStatus == "createde") {
        let params = {
          name: this.addform.cn_name,
          type: this.form.numType,
          demo: this.addform.demo,
          order_no: this.addform.order_no,
          edit_flag: this.addform.radio
        };
        let url = "/p/cate/add";
        this.$axios.post(url, this.$qs(params)).then(res => {
          if (res.data.code == 0) {
            this.$message({
              // message:response.data.message,
              message: "添加成功",
              type: "success"
            });
            this.getData();
            this.centerDialogVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.data.message
            });
          }
        });
      } else {
        let params = {
          name: this.addform.cn_name,
          type: this.form.numType,
          demo: this.addform.demo,
          order_no: this.addform.order_no,
          edit_flag: this.addform.radio,
          uuid: this.addform.uuid
        };
        let url = "/p/cate/update";
        this.$axios.post(url, this.$qs(params)).then(res => {
          if (res.data.code == 0) {
            this.$message({
              // message:response.data.message,
              message: "修改成功",
              type: "success"
            });
            this.getData();
            this.centerDialogVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.data.message
            });
          }
        });
      }
    },
    // 获取单个已选中指标
    getIndicator(data, type) {
      // this.dialog_type = true;
      // let arr = [
      //   {
      //     uuid: "A34B93ED-625F-9E8F-61AD-0664257FEE7F",
      //     cn_name: "背肌耐力"
      //   }
      // ];
      // this.zbList.map(item => {
      //   arr.map(v => {
      //     if (item.uuid == v.uuid) {
      //       item.isCheck = true;
      //     }
      //   });
      // });
      // this.$forceUpdate();
      // return;
      this.setUuid = data.uuid;
      let url = "/p/cate/readNorm";
      this.$axios.post(url, this.$qs({ uuid: data.uuid })).then(res => {
        if (res.data.code == 0) {
          if(type) {
            this.lookTitle = data.name + ' - 查看指标' 
            this.lookZbList = res.data.data
            this.lookDialogVisible = true
          } else {
            this.zbList.map(item => {
              item.use_num = 0
              res.data.data.map(v => {
                if (item.uuid == v.uuid) {
                  item.isCheck = true;
                  item.use_num = v.use_num
                }
              });
            });
            this.dialog_type = true;
          }
          this.$forceUpdate();
        } else {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      });
    },
    //获取指标分类
    getIndicators(row) {
      let url = "/p/norm/getNormalNorm";
      this.$axios.post(url).then(res => {
        if (res.data.code == 0) {
          this.zbList = res.data.data;
          this.zbList.map(item => {
            item.isCheck = false;
            item.isShow = true;
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      });
    },
    // 保存指标
    save() {
      let arr = this.zbList.filter(item => item.isCheck);
      let uuidarr = arr.map(item => item.uuid);
      let params = {
        norm_uuid_str: uuidarr.join(","),
        uuid: this.setUuid
      };

      let url = "/p/cate/updateNorm";
      this.$axios.post(url, this.$qs(params)).then(res => {
        if (res.data.code == 0) {
          this.$message({
            message: res.data.message,
            type: "success"
          });
          this.dialog_type = false;
          this.getData();
        } else {
          this.$message({
            message: res.data.message,
            type: "error"
          });
        }
      });
    },
    //删除数据
    Delete(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
        cancelButtonClass: "",
        center: true
      }).then(res => {
        let params = {
          uuids: data.uuid,
          type: this.form.numType
        };
        let url = "/p/cate/delete";
        this.$axios.post(url, this.$qs(params)).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: res.data.message,
              type: "success"
            });
            this.getData();
          } else {
            this.$message({
              message: res.data.message,
              type: "error"
            });
          }
        });
      });
    },
    //多选删除
    someDels() {
      let that = this;
      //删除数据
      if (that.sels.length == 0) {
        that.$message({
          message: "请选择要删除的数据",
          type: "error",
          duration: 4000
        });
        return;
      }
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
        cancelButtonClass: "",
        center: true
      }).then(() => {
        let params = [];
        let url = "/p/cate/delete";

        for (let i = 0; i < this.sels.length; i++) {
          params[i] = this.sels[i].uuid;
        }
        let ids = params.join(",");
        let data = { uuids: ids, type: this.form.numType };
        this.$axios.post(url, this.$qs(data)).then(res => {
          if (res.data.code == 0) {
            this.getData();
            this.$message({
              message: res.data.message,
              type: "success"
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error"
            });
          }
        });
      });
    },
    //修改状态
    ChangeState(data) {
      let url = "/p/cate/changeshow";
      let params = {
        uuid: data.uuid
        // type: this.form.numType
      };
      let that = this;
      this.$axios.post(url, this.$qs(params)).then(res => {
        if (res.data.code == 0) {
          this.$message({
            message: res.data.message,
            type: "success"
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }

        that.getData();
      });
    },
    lookCancel () {
      this.lookDialogVisible = false
    }
  }
};
</script>
<style lang='scss' scoped>
.disColor {
  color: #ccc;
}
.blueColors {
  color: #1a9f16;
}
/deep/.el-dialog__title {
  line-height: 1.25rem;
  font-size: 0.9375rem;
  color: #303133;
  font-weight: 600;
}
/deep/.el-table th > .cell {
  padding-left: 15px;
}
.child-top {
  .input-from {
    .searchBtn{
      margin-top: 10px;
    }
  }
  .operation-button {
    // float: left;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.child-top .operation-button .del_btn:hover {
  background: #eb1e44;
  color: #ffffff;
}
.look_item_content {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 30px 20px;
  max-height: 260px;
  overflow-y: auto;
  li {
    color: #000;
    line-height: 30px;
  }
}
</style>